@import "../margins_padding.sass";

@font-face {
  font-family: helvetica;
  src: url("./assets/fonts/Helvetica.ttc");
}

@font-face {
  font-family: "Monsterrat bold";
  src: url("./assets/fonts/Montserrat-Bold.otf");
}

// global styles
* {
  box-sizing: border-box;
  font-family: helvetica;
  letter-spacing: 0.1px;
}

.App {
  width: 100%;
  height: 100vh;
}
