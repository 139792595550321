@for $i from 0 through 100 {
  .w#{$i}p {
    width: #{$i}% !important; }
  .m#{$i} {
    margin: 0px + $i !important; }
  .mt#{$i} {
    margin-top: 0px + $i !important; }
  .mb#{$i} {
    margin-bottom: 0px + $i !important; }
  .ml#{$i} {
    margin-left: 0px + $i !important; }
  .mr#{$i} {
    margin-right: 0px + $i !important; } }

@for $i from 1 through 500 {
  .min-height-#{$i}px {
    min-height: #{$i}px !important; } }

@for $i from 1 through 500 {
  .max-height-#{$i}px {
    max-height: #{$i}px !important; } }

@for $i from 1 through 500 {
  .height-#{$i}px {
    height: #{$i}px !important; } }

@for $i from 1 through 500 {
  .width-#{$i}px {
    width: #{$i}px !important; } }


@for $i from 0 through 20 {
  .p#{$i} {
    padding: 0px + $i !important; }
  .pt#{$i} {
    padding-top: 0px + $i !important; }
  .pb#{$i} {
    padding-bottom: 0px + $i !important; }
  .pl#{$i} {
    padding-left: 0px + $i !important; }
  .pr#{$i} {
    padding-right: 0px + $i !important; } }
