.home {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    top: 0;
    background-image: url("../../assets/images/background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    .nav {
        //position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: none !important;
      }

    

    .swap {
    // display: inline-block;
    // position: fixed;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    width: 500px;
    height: 800px;

    .notice{
        width: 500px;
        height: 550px;
        border-radius: 10px;
        background-color: #fff;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 20px;
        // text-align: justify;
        p{
            color: #ff8936;
            font-weight: 900;
            line-height: 20px;
        }
      }
     .swap-box{ 
         height: 500px;
    border-radius: 10px;
    background-color: rgba(0,0,0,.35);
    padding: 30px;

    .top {
        height: 80px;
        // display: flex;
        // justify-content:space-between;

        .selector{
            background-color: rgba(0,0,0,.35);
            height: 60px;
            width: 40%;
            border-radius: 10px;
            display: flex;
            .selected{
                width: 50%;
                border-radius: 10px;
                height: 60px;
                color: #fff;
                padding-top: 20px;
                text-align: center;
        font-size: 16px;
        font-family: 'Monsterrat bold';
        cursor: pointer;
        &:hover {
            background-color: #ff8936;
          }
          &.active {
            background-color: #ff8936;
          }
            }
            .unselected {
                width: 50%;
                border-radius: 10px;
                height: 60px;
                color: #fff;
                padding-top: 20px;
                text-align: center;
                cursor: pointer;
                &:hover {
                    background-color: #ff8936;
                  }
                  &.active {
                    background-color: #ff8936;
                  }
            }
        }
        .settings{
            height: 60px;
            border-radius: 10px; 
            float: right;
            //display: flex;
            .icon {
                background-color: rgba(0,0,0,.35);
                width: 60px;
                height: 60px;
                float: right;
                border-radius: 10px;
                padding-top: 15px;
                text-align: center;
                cursor: pointer;
                margin-left: 10px;
            }
        }
    }

    .pool{
        padding-top: 50px;

        .pool-handle{
            margin-top: 20px;
            background-color: rgba(0,0,0,.35);
            height: 150px;
            width: 100%;
            border-radius: 10px;
        }
    }
    label {
        color: #fff;
        font-size: 16px;
        font-family: 'Monsterrat bold';
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .balance{
        float: right;
    }

    input{
        background: rgba(0,0,0,.15);
        height: 60px;
        border: 0;
        border-radius: 10px;
        width: 100%;
        padding: 0 20px;
        margin-top: 5px;
        margin-bottom: 15px;
        color: #fff;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .switch {
        display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
        height: 100px;
    }

    .main-btn {
        margin-top: 50px;
        background: #ff8936;
        border-color:#ff8936;
        color: #fff;
        height: 60px;
        width: 100%;
        font-weight: 600;
        border-radius: 10px;
        font-family: 'Monsterrat bold';
        justify-content:center;
        align-items: center;
    }
    .list{
        height: 70px;
        border-radius: 10px;
        background-color: rgba(0,0,0,.35);
        padding: 20px;
        display: flex;
        cursor: pointer;
        margin-bottom: 20px;
        .logo {
            height: 30px;
            width: 30px;
          }
          .title{
            color: #fff;
            font-size: 15px;
            font-family: 'Monsterrat bold';
            margin-left: 20px;
            margin-top: 5px;
          }
    }
}
    }

    .main-btn {
        margin-top: 50px;
        background: #ff8936;
       
        border: 1px solid #ff8936;
        color: #fff;
        height: 60px;
        width: 100%;
        font-weight: 600;
        border-radius: 10px;
        font-family: 'Monsterrat bold';
    }
    button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
  }
  
}

@media (max-width: 767px) {
    .home {
        .swap {
            width: 400px;
            .notice{
                width: 400px;
                height: 400px;
                p{
                    font-weight: 900;
                    line-height: 20px;
                    font-size: 12px;
                    line-height: 16px;
                }
                .ack {
                    display: block;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
            .swap-box{
                padding: 20px;

                label{
                    font-size: 12px;
                }
            }
        }
        .main-btn {
            margin-top: 20px;
            background: #ff8936;
            border-color:#ff8936;
            color: #fff;
            height: 60px;
            width: 100%;
            font-weight: 600;
            border-radius: 10px;
            font-family: 'Monsterrat bold';
        }
        
    }
}

@media (max-width: 400px) {
    .home {
        .swap {
            width: 350px;
            .notice{
                width: 350px;
                height: 450px;
                padding: 20px;
                p{
                    font-weight: 900;
                    line-height: 20px;
                    font-size: 12px;
                    line-height: 16px;
                }
                .ack {
                    display: block;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
            .swap-box{
                padding: 15px;

                label{
                    font-size: 12px;
                }
            }
        }
        .main-btn {
            margin-top: 20px;
            background: #ff8936;
            border-color:#ff8936;
            color: #fff;
            height: 50px;
            width: 100%;
            font-weight: 600;
            border-radius: 10px;
            font-family: 'Monsterrat bold';
        }
        
    }
}