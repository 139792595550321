.nav {
    width: 100%;
    height: 75px;
    padding: 0 70px;
    position: relative;
    z-index: 30;
    display: flex;
    // justify-content:space-between;
    .c-pointer {
      cursor: pointer;
    }
    .logo {
      margin-top: 17px;
      height: 44px;
    }

    .wallet {
        margin-top: 17px;
        display: flex;
        margin-left: auto;
    margin-right: auto;
        
        .selector{
            background-color: rgba(0,0,0,.35);
            height: 60px;
            border-radius: 10px;
            display: flex;
            width: 400px;
            .selected{
                .dot{
                        content: "";
                        width: 6px;
                        height: 6px;
                        background-color: rgb(0, 157, 154);
                        position: absolute;
                        left: 0px;
                        top: calc(50% - 2px);
                        border-radius: 50%;
                }
                border-radius: 10px;
                height: 60px;
                color: #fff;
                padding-top: 20px;
                text-align: center;
                width: 50%;
                flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 10px;
        font-size: 14px;
        font-family: 'Monsterrat bold';
        background-color: #ff8936;
        cursor: pointer;
            }

            .unselected {
                width: 50%;
                border-radius: 10px;
                height: 60px;
                color: #fff;
                font-size: 14px;
                font-family: 'Monsterrat bold';
                padding-top: 20px;
                text-align: center;
                cursor: pointer;
            }
        }
        .settings{
            height: 60px;
            border-radius: 10px; 
            display: flex;
            .icon {
                background-color: rgba(0,0,0,.35);
                width: 60px;
                height: 60px;
                float: right;
                border-radius: 10px;
                padding-top: 15px;
                text-align: center;
                cursor: pointer;
                margin-left: 10px;
            }
        }
    }
  }
  @media (max-width: 767px) {
      .nav {
          height: 50px;
          padding: 0 30px;
    .wallet{
        
        margin-right: 0 !important;
        .selector{
            width: 200px;
            .unselected{
                font-size: 12px;
                padding-top: 15px;
            }
        }
    }
}
}